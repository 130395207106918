<template>
	<div class="callthepolice">
		<div class="callthepolice-T">
			<div class="callthepolice-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="callthepolice-searchs">
					<inputs @inputs="inputValue" @pickers="pickersValue" :pickValue="value4" :inputShow="false" :pickerShow="true"
						inputPlace="请输入名称查询数据"></inputs>
					<div class="callthepolice-tit-mai1-btn">
						<el-button type="primary" icon="el-icon-search" @click="drawer=true">更多查询</el-button>
					</div>
				</div>
			</div>
			<div class="callthepolice-TR">
				<div class="callthepolice-searchsMai">
					<el-button type="warning" icon="el-icon-menu" @click="setall()">批量</el-button>
					<el-button type="danger" icon="el-icon-download" @click="GetexceApi()">Excel</el-button>
				</div>
			</div>
		</div>
		<div class="callthepolice-B" data-aos="flip-left" data-aos-delay="0">
			<div class="callthepolice2">
				<el-table :data="tableData" :row-class-name="tableRowClassName" ref="multipleTable" border
					style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column type="selection" width="55" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_Title" label="报警类型" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_sn" label="SN" width="150" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_Name" label="设备名称" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="传感器" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							[{{scope.row.T_Id}}]-{{scope.row.T_Name}}
						</template>
					</el-table-column>
					<el-table-column prop="T_T" label="温度" width="70" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_RH" label="湿度" width="70" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="时间" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.CreateTime | Time(scope.row.CreateTime)}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<el-dropdown split-button type="danger" size="mini" @command="allpir($event,scope.row)">
								操作
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="a"><i class="el-icon-edit-outline"
											style="font-size: 18px;"></i>处理</el-dropdown-item>
									<el-dropdown-item command="b"><i class="el-icon-notebook-2"
											style="font-size: 16px;"></i>处理记录</el-dropdown-item>
									<el-dropdown-item command="c"><i class="el-icon-delete"
											style="font-size: 16px;"></i>删除报警</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="callthepolice-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="报警处理" :visible.sync="dialogVisible" width="40%" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" ref="ruleForm" label-width="80px" class="demo-ruleForm">
					<el-form-item label="处理备注">
						<el-input type="textarea" v-model="ruleForm.T_Text"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="danger" @click="submitForm()" v-loading.fullscreen.lock="fullscreenLoading">
							立即提交</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="处理记录" :visible.sync="dialogVisible1" :append-to-body="true">
			<div class="NewUser" style="height: 500px;overflow-y: auto;">
				<el-timeline>
					<el-timeline-item :timestamp="T_sn" placement="top" v-for="(item,index) in Loglist" :key="index">
						<el-card>
							<!-- <h4>{{item}}</h4> -->
							<p>{{item}}</p>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
		</el-dialog>
		<el-drawer title="更多查询" :visible.sync="drawer" :append-to-body="true">
			<div class="drawerPir" style="margin-left: 10px;">
				<el-form label-width="80px">
					<el-form-item label="快速查询">
						<el-button @click="TimeFor">近40天</el-button>
					</el-form-item>
					<el-form-item label="年月查询">
						<div style="display: flex;align-items: center;justify-content: space-between;color: #ccc;margin-right: 20px;">
							<el-date-picker v-model="value2" type="month" value-format="yyyy-MM" placeholder="开始年月">
							</el-date-picker>
							<div style="margin: 0 10px;">-</div>
							<el-date-picker v-model="value3" type="month" value-format="yyyy-MM" placeholder="结束年月">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="Tijiao">立即查询</el-button>
						<el-button @click="drawer=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>

	</div>
</template>
<script>
	import {
		DevList,
		DevToExcel,
		DevToDel,
		DevToLog,
		DevEdit
	} from '../../api/equipmentManagement/callthepolice.js'
	import Axios from 'axios'
	import {formatDate} from '../../utils/Times.js'
	import fileDownload from 'js-file-download'
	let moment = require('moment');
	export default {
		data() {
			return {
				drawer: false, //筛选
				value2: '',
				value3: '',
				value4:[],
				fullscreenLoading: false,
				bool: null, //判断是点击的批处理还是单处理
				Loglist: null,
				T_sn: '',
				total: 0,
				multipleTable: [],
				pages: {
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 10,
				},
				value1: '',
				ruleForm: {
					T_id: '',
					T_Text: '',
				},
				dialogVisible: false,
				dialogVisible1: false,
				formInline: {
					user: '',
					region: ''
				},
				tableData: []
			}
		},
		filters: {
			Time: function(time) { //时间戳转换
				if (time) {
					var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					let y = date.getFullYear();
					let MM = (date.getMonth() + 1).toString().padStart(2, '0');
					let d = date.getDate().toString().padStart(2, '0');
					let h = date.getHours().toString().padStart(2, '0');
					let m = date.getMinutes().toString().padStart(2, '0');
					let s = date.getSeconds().toString().padStart(2, '0');
					return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
				} else {
					return '0000-00-00  00-00-00'
				}
			},
		},
		mounted() {
			this.GetDevListApi()
		},
		methods: {
			TimeFor(){//近40天
				this.pages.Time_start = formatDate(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 40)
				this.pages.Time_end = formatDate(new Date())
				this.pages.page = 1
				this.GetDevListApi()
				this.drawer = false
				////console.log(this.pages)
			},
			Tijiao() {
				var sta = this.value2.replace('-','')
				var end = this.value3.replace('-','')
				if(this.value2===''){
					this.$message.error('请选择开始年月')
					return
				}else if(this.value3===''){
					this.$message.error('请选择结束年月')
					return
				}else if(sta>=end){//开始时间大于结束时间，
					this.$message.error('查询年月区间选择不正确,请重新选择')
					return
				}else{
					this.pages.Time_start = this.value2
					this.pages.Time_end = this.value3
					this.pages.page = 1
					this.GetDevListApi()
					this.drawer = false
				}
			},
			tableRowClassName({
				row
			}) {
				if (row.T_State == 2) { //等于2的时候红色
					return 'warning-row';
				}
				return '';
			},
			GetexceApi() {
				if (this.pages.Time_start === '' && this.pages.Time_end === '') {
					this.$message.error('请选择开始时间与结束时间')
					return
				} else {
					DevToExcel({
						T_title: "",
						T_sn: "",
						T_id: "",
						Time_start: "2022-11-01 00:00:00",
						Time_end: "2022-11-02 00:00:00",
					}).then(res => {
						////console.log('excel', res)
						if (res.data.Code === 200) {
							Axios({
								method: 'get',
								url: res.data.Data,
								responseType: 'blob'
							}).then(res => {
								////console.log(res)
								this.$message.success('导出Excel成功')
								fileDownload(res.data, moment(new Date()).format('YYYYMMDDHHmmss') +
									'.xlsx');
							});
						}
					})
				}
			},
			GetDevListApi() {
				DevList(this.pages).then(res => {
					////console.log(res)
					if (res.data.Code === 200 && res.data.Msg === "ok!") {
						this.tableData = res.data.Data.Data
						this.total = res.data.Data.Num
					}
				})
			},
			inputValue(e) {
				////console.log(e)
			},
			pickersValue(e) {
				////console.log('ee', e)
				if (e != null) {
					this.pages.Time_start = e[0]
					this.pages.Time_end = e[1]
				} else {
					this.pages.Time_start = ""
					this.pages.Time_end = ""
				}
				this.pages.page = 1
				this.GetDevListApi()
			},
			async submitForm() {
				this.fullscreenLoading = true
				this.dialogVisible = false
				var that = this
				if (this.bool === 0) { //点击批处理
					////console.log('批量')
					this.PICHULI()
				} else { //单处理
					let bl = await this.GETEDIT(this.ruleForm)
					////console.log('danchuli', bl)
					if (bl.data.Code === 200) {
						that.GetDevListApi()
						setTimeout(function() {
							that.fullscreenLoading = false
						}, 1000)
						setTimeout(function() {
							that.$message.success('报警处理完成')
						}, 1200)
					}
				}
			},
			PICHULI() {
				var Arr = this.$refs.multipleTable.selection
				var that = this
				var jishu = 0
				Arr.forEach(async (item) => {
					this.ruleForm.T_id = item.Id
					let bl = await this.GETEDIT(this.ruleForm)
					if (bl.data.Code === 200) {
						jishu = jishu + 1
					}
					if (jishu == Arr.length) {
						that.GetDevListApi()
						setTimeout(function() {
							that.fullscreenLoading = false
						}, 1000)
						setTimeout(function() {
							that.$message.success('报警批量处理完成')
						}, 1200)
					}
				})
			},
			setall() {
				var Arr = this.$refs.multipleTable.selection
				if (Arr.length < 1) {
					this.$message.error('批量处理异常，请勾选至少一项进行操作')
				} else {
					this.bool = 0 //0为点击的批处理
					this.dialogVisible = true
				}
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetDevListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetDevListApi()
			},
			allpir(i, item) {
				var that = this
				if (i === 'a') { //1为点击的单处理
					this.bool = 1
					this.ruleForm.T_id = item.Id
					this.dialogVisible = true
				} else if (i === "b") { //同步分类
					////console.log('处理记录', i, item)
					DevToLog({
						T_id: item.Id
					}).then(res => {
						////console.log('rizi', res)
						if (res.data.Code === 200 && res.data.Msg === "ok!") {
							that.dialogVisible1 = true
							that.Loglist = res.data.Data.T_Log
							that.T_sn = res.data.Data.T_sn
						}
					})
				} else if (i === "c") { //同步大屏
					////console.log('删除', i, item)
					this.$confirm('此操作将执行删除, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(() => {
						DevToDel({
							T_id: item.Id
						}).then(res => {
							if (res.data.Code == 200) {
								that.$message.success('删除成功');
								that.pages.page = 1
								that.GetDevListApi()
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			GETEDIT(obj) {
				return new Promise(resolve => {
					DevEdit(obj).then(function(res) {
						resolve(res);
					})
				});
			},
		}
	}
</script>

<style lang="scss">
	.el-dropdown-menu--mini .el-dropdown-menu__item {
		line-height: 35px;
		padding: 0 10px;
		font-size: 12px;
	}

	.el-table .warning-row {
		background: rgba(255, 0, 0, 0.23);
	}

	.callthepolice {
		user-select: none;

		.callthepolice-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.callthepolice-TL {
				display: flex;
				align-items: center;

				.callthepolice-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.callthepolice-tit-mai1-btn {}

					.callthepolice-searchs0 {}

					.callthepolice-searchs1 {}
				}
			}

			.callthepolice-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		@media screen and (max-width: 1275px) {
			.bre {
				display: none;
			}

			.callthepolice-T {
				display: flex;
				justify-content: space-between;
				background: rgba(#fff, 1);
				height: 80px;
				box-shadow: 5px 5px 10px -10px #000;

				.callthepolice-TL {
					width: 100%;
					flex: 1;

					.callthepolice-searchs {
						margin-left: 0;
					}
				}

				.callthepolice-TR {
					display: flex;
				}
			}
		}

		@media screen and (max-width: 1060px) {
			.callthepolice-T {
				display: flex;
				flex-direction: column;
				background: rgba(#fff, 1);
				height: 80px;
				box-shadow: 5px 5px 10px -10px #000;

				.callthepolice-TL {
					width: 100%;

					.callthepolice-searchs {
						margin-left: 0;
					}
				}

				.callthepolice-TR {
					margin-top: 10px;
					width: 100%;
					display: flex;
				}

			}
		}

		.callthepolice-B {
			background: rgba(#fff, 1);
			margin: 10px;
		}

		.callthepolice2 {
			// border-radius: 10px;
			overflow: hidden;
			margin: 10px;
			padding: 20px;
		}

		.callthepolice-pagination {
			// background: rgba(#31353e,1);
			padding: 30px 20px;
			margin: 10px;
			border-radius: 2px;
			display: flex;
			// justify-content: center;
			// .el-pagination.is-background .btn-next,
			// .btn-prev,
			// .el-pagination.is-background .el-pager li {
			// 	//未选中
			// 	margin: 0 5px;
			// 	background-color: #222;
			// 	color: #fff;
			// 	min-width: 30px;
			// 	border-radius: 2px;
			// }

			// .el-pagination.is-background .el-pager li:not(.disabled).active {
			// 	//选中
			// 	background-color: #fff;
			// 	color: #222;
			// }

			// .el-pagination__total {
			// 	//总total
			// 	margin-right: 10px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }

			// .el-pagination__jump {
			// 	//跳转页
			// 	margin-left: 24px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }
		}
	}
</style>
