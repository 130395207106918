import { render, staticRenderFns } from "./callthepolice - 副本.vue?vue&type=template&id=9fb2d0be&"
import script from "./callthepolice - 副本.vue?vue&type=script&lang=js&"
export * from "./callthepolice - 副本.vue?vue&type=script&lang=js&"
import style0 from "./callthepolice - 副本.vue?vue&type=style&index=0&id=9fb2d0be&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports